import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  // NOTE: 以下のサイトを参考にプライバシーポリシーを作成
  // https://www.rocketdocs.jp/
  // https://togetter.com/info/privacy
  return (
    <Layout location={location} title={siteTitle}>
      <article
        className="static-post"
        style={{ padding: `0 var(--spacing-4) var(--spacing-10)` }}
      >
        <h3>プライバシーポリシー</h3>
        <p>
          {siteTitle}
          （以下「当サイト」）は、利用者の皆様から取得した個人情報の保護に万全を尽くし、法令およびその他の規範を遵守いたします。
          そして、個人情報の取り扱いについて以下の通り実施いたします。
        </p>
        <h4>個人情報の利用範囲について</h4>
        <p>
          本プライバシーポリシー（以下「本ポリシー」といいます。）は、当サイトが利用者から個人情報を取得、利用及び管理するときに適用されます。
        </p>
        {/* <p>
          利用者の皆様から取得した個人情報は以下の目的のために利用させていただきます。
        </p>
        <ol>
          <li>本サービスの利用に必要な会員の管理のためのシステム運営</li>
          <li>本サービスの利便性を向上させるためのシステム運営</li>
          <li>
            メンテナンス情報や、重要なご連絡など、本サービスを運用する上で必要な皆様へのお知らせ
          </li>
          <li>本サービスへのご質問、お問い合わせなどに関する回答</li>
          <li>
            利用者の皆様の行動、性別、本サービス内でのアクセス履歴などを用いたターゲティング広告の配信
          </li>
          <li>
            本サービス上で、個人を特定できない範囲においての統計情報の作成および利用
          </li>
          <li>当サイトの他サービスへの情報の連携・情報の共有</li>
          <li>本サービスの新規開発に必要なデータの解析や分析</li>
          <li>
            当サイトが提携している先への個人を特定できず会員の許諾を得た範囲内での情報の提供
          </li>
        </ol> */}
        <h4>個人情報利用制限について</h4>
        <p>
          当サイトは個人情報を管理する上で、ご本人の同意なし、もしくは利用範囲内のものを超えて、個人情報を取り扱うことはありません。ただし、以下に定める場合においてはこの限りではありません。
        </p>
        <ol>
          <li>法令に基づく場合</li>
          <li>利用者の皆様の同意があった場合。</li>
          <li>
            人の生命、身体または財産の保護のために必要があり、さらに利用者の皆様の同意を得るのが困難な場合
          </li>
          <li>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザーご本人の承諾を得ることが困難である場合
          </li>
          <li>
            国の機関、地方公共団体、またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合かつ利用者の皆様の同意を得ることにが困難である場合
          </li>
          <li>
            特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律の第4条に該当する請求があり、正当なものと当サイトが判断した場合
          </li>
          <li>
            「個人情報の利用目的」の範囲内で、業務遂行にあたり必要な範囲において、個人情報取り扱いを第三者に預託する場合（ただし、預託に必要な最低限の情報に限ります。また当サイトは、その預託先の第三者において、最大限の管理を行います）
          </li>
        </ol>
        <h4>個人情報の利用に対する変更について</h4>
        <p>
          当サイトは個人情報の利用に関して、変更があった場合、該当する利用者の方に通知をし、また公表をいたします。
        </p>
        <h4>個人情報の管理について</h4>
        <ol>
          <li>
            当サイトでは、業務遂行に必要な範囲内で、権限を与えられた者のみが個人情報を取り扱います。
          </li>
          <li>
            当サイトでは、個人情報の安全な管理について、従業員にたいして適切な監督を行います。
          </li>
        </ol>
        <h4>個人情報の訂正削除について</h4>
        <p>
          当サイトでは、利用者本人またはその代理人より、該当する個人情報の訂正または削除の申し出があった場合、業務遂行上必要な範囲において、合理的な期間で調査を行い、訂正、削除を行います。
        </p>
        <h4>Cookie、アクセスログについて</h4>
        <p>
          当サイトでは、本サービスへの利便性をあげるために、Cookie・アクセス解析・統計データなどを保存、利用します。また当サイトでは
          Cookie や JavaScript
          などの技術を利用し、利用者の方の行動の履歴を取得することがあります。また、これらには個人情報は含まれません。
        </p>
        <h4>プライバシーポリシーに対するお問い合わせ</h4>
        <p>
          当サイトのプライバシーポリシーに関するお問い合わせは、サイト下部の「お問い合わせ」リンク先よりお願いいたします。
        </p>
        <span>2022年08月04日 制定</span>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Privacy

export const Head = () => (
  <Seo title="プライバシーポリシー" description="プライバシーポリシー" />
)
